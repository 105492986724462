import React from "react";

import Layout from "../../../components/Layout/Layout";

const Article07052020 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              S&T AG: STRONG Q1 2020 DESPITE CORONA CRISIS
            </h1>
            <p className="bullet">
              {" "}
              Revenue growth of 20% to EUR 270.1 million (PY: EUR 225.1 million)
            </p>
            <p className="bullet">
              {" "}
              EBITDA increased by 18.2% to EUR 25 million (PY: EUR 21.2 million)
            </p>
            <p className="bullet">
              Operating cash flow improved strongly by EUR 19.4 million to EUR
              6.4 million (PY: minus EUR 13 million)
            </p>
            <p className="bullet">
              {" "}
              New guidance 2020: Revenues EUR 1,150 million and EUR 115 million
              EBITDA
            </p>
            <p>
              Linz, 07.05.2020. The technology group S&T AG is delivering its
              45th record quarter in a row at the start of the year with the
              first quarter of 2020. This even though the corona crisis is
              currently having a strong impact on the global economy and
              personal everyday life. Both revenues and profitability increased
              further compared to the same quarter last year. Revenues increased
              by around 20% to EUR 270.1 million (PY: EUR 225.1 million), while
              gross profit rose from EUR 85.3 million to EUR 107.8 million,
              reaching a new record gross margin level of 39.9%. EBITDA
              increased by a pleasing 18.2% to EUR 25 million (PY: EUR 21.2
              million). Earnings per share increased by 7% to EUR 15 cents (PY:
              EUR 14 cents).
            </p>
            <p>
              The PEC ("Profit Efficiency Cash") program launched in the summer
              of 2019 is continuing to have an effect: the operating cash flow
              in the first quarter of 2020 improved by EUR 19.4 million from
              minus EUR 13 million to EUR 6.4 million. This leads to a sustained
              strong financial situation for the S&T Group, which has, despite
              further share buybacks and the ongoing repayment of financial
              liabilities, liquid funds of EUR 276.1 million at its disposal.
            </p>
            <p>
              Overall, the first quarter was therefore very positive for S&T AG,
              but from March onwards was burdened by the first significant
              effects of the Covid-19 pandemic. While sectors such as aviation,
              entertainment or tourism are currently experiencing a complete
              collapse, the S&T Group sees growth in communication and medical
              technology as well as online trading. Overall, the Covid-19
              pandemic, which started in Asia at the beginning of the year, and
              its effects on the supply chains, had a negative impact of around
              EUR 1.8 million on the results of Group S&T, otherwise the results
              would have been correspondingly higher.
            </p>
            <p>
              In the short term, S&T AG will not be able to completely escape
              the current developments, but in the medium term it sees itself as
              one of the winners of the Corona crisis. With its broad customer
              base, S&T is well diversified and resilient. The high level of
              cash and cash equivalents and further unused credit lines make the
              S&T Group financially strong and crisis-proof. This strength can
              and will be used to take over financially weaker competitors at
              favorable terms during the crisis.
            </p>
            <p>
              On the basis of the aforementioned strengths, the figures for the
              first quarter of 2020 and the latest forecasts, the Management
              Board of S&T AG considers it likely that business development for
              the current financial year will exceed previous year's level
              despite the corona crisis. This is underpinned by the further
              increase in orders on hand to EUR 873m (31.12.2019: EUR 841m). In
              2020, revenues are expected to reach around EUR 1,150 million and
              profitability around EUR 115 million EBITDA. Both figures are
              around 3% above the 2019 financial year, which means that the S&T
              Group will continue its profitable growth path, despite the
              massive distortions in the global economy. Thus, even with the
              corona pandemic, an EBITDA margin of 10% should be achieved for
              the first time in 2020. "In the medium term, S&T will be a winner
              in the crisis. We therefore reaffirm our ambitious Agenda 2023
              target of EUR 2 billion in revenue with an EBITDA of EUR 220
              million", states Hannes Niederhauser, CEO of S&T AG.{" "}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Article07052020;
